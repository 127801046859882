import Header from "../components/Header.jsx";
import Nav from "../components/Nav.jsx";
import { useEffect, useState } from "react";
import api from "../statics/api.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader.jsx";
import Overlay from "../components/Overlay.jsx";
import SearchInput from "../components/search-input.component.jsx";

import { useDispatch, useSelector } from "react-redux";
import { remove_all } from "../reducers/buildYourPC.js";

import Cart from "../components/cart.compontent.jsx";
import MobileMenu from "../components/menu.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";
import Footer from "../components/footer.component.jsx";
import BreadCrump from "../components/BreadCrump.component.jsx";
import AddIcon from "../assets/icons/add-sign-gray.svg";
import SelectProducsByCategory from "../components/SelectByCategory.component.jsx";
import BuildYourPCSelectedProduct from "../components/BuildYourPCSelectedProduct.jsx";

import cart, { add_to_cart, set_cart } from "../reducers/cart.js";
import { set_username } from "../reducers/username.js";
import { Helmet } from "react-helmet";


function BuildYourPCPage() {
  const selectedProducts = useSelector((state) => state.buildYourPC.products);

  const [products, setProducts] = useState({
    MotherBoard: { id: 17, products: [], slug: "motherboard" },
    CPU: { id: 14, products: [], slug: "cpu" },
    RAM: { id: 22, products: [], slug: "ram-pc" },
    SSD: { id: 27, products: [], slug: "ssd-sata-2.5" },
    NVME: { id: 28, products: [], slug: "nvme-m.2" },
    HDD: { id: 25, products: [], slug: "hdd-3.5" },
    // "COMPUTER CASE": { id: 31, products: [], slug: "computer-case" },
    "COMPUTER CASE (NO POWER SUPPLY)": { id: 31, products: [], slug: "case-no-psu" },
    "COMPUTER CASE COMBO": { id: 32, products: [], slug: "case-combo" },
    "POWER SUPPLY": { id: 34, products: [], slug: "power-supply" },
    "PC COOLING": { id: 35, products: [], slug: "pc-cooling" },
    "ACCESSORIES": { id: 39, products: [], slug: "accessories" },
    "GPU": { id: 46, products: [], slug: "graphic-cards" },
    "Monitors": { id: 51, products: [], slug: "monitors" },
    // "Laptops": { id: 50, products: [], slug: "laptops" },
  });
  const [currentCategorySlug, setCurrentCategorySlug] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };

  const handleRequest = async () => {
    for (let i = 0; i < 100; i++) {
      try {
        const response = await fetch(`${api}popular_products.php`, {
          method: "GET",
        });
        let responseData = await response.json();
        if (responseData.state) {
          setProducts(responseData.products);
        }
        return;
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };

  const ProductsSkeleton = () => (
    <div className="mt-10 flex flex-col gap-6">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-2">
        {Array.from({ length: 16 }).map((_, index) => (
          <div
            key={index}
            className="min-w-[150px] animate-pulse p-2 bg-bw-02 flex relative flex-col gap-2 rounded-lg flex-1"
          >
            <div role="status" className="max-w-sm">
              <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full max-w-[100px] mb-2.5"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );


  const SelectProductComponent = ({ name, categoryID, slug }) => (
    <div className="p-4  bg-bw-02  text-white overflow-auto rounded-lg">
      {/* Name & Add Btn */}
      <div
        className="flex justify-between  h-20  items-center min-w-56  flex-1"
        onClick={() => setCurrentCategorySlug(slug)}
      >
        <div className="font-['kensmark-03'] font-bold text-xl 2xl:text-2xl ">
          {name}
        </div>

        <div className="text-white">
          <img src={AddIcon} alt="Add Icon" className="cursor-pointer"></img>
        </div>
      </div>

      {/* Selected Products */}
      <div className="flex flex-col gap-2 overflow-auto">
        {selectedProducts.map((ele) => {
          if (ele.categorySlug === slug) {
            return (
              <div className="">
                <BuildYourPCSelectedProduct
                  data={ele}
                ></BuildYourPCSelectedProduct>
              </div>
            );
          }
        })}
      </div>
    </div>
  );

  const getCookie = (cookieName) => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + "=")) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    // If cookie not found, return null
    return false;
  };

  const addToCart = async () => {

    setLoading(true);
    let postData = new FormData();
    const session = getCookie("session");
    let productsToUpload = []

    if (session) {
      for(var i=0;i<selectedProducts.length;i++) {
        let product = selectedProducts[i]
        if ( product.qty > +product.max_purchase_qty) {
          toast.error(product.title +": Quantity is out of acceptable range, max quantity is " + product.max_purchase_qty + " piece/pieces")
          return
        }
      }
    }

    for(var i=0;i<selectedProducts.length;i++) {

      let product = selectedProducts[i]

      // If not logged in
      if (!session) {
        let cart = localStorage.getItem("cart") || [];
        cart = JSON.parse(cart)
        let is_found = false;
        let index = 0;

        // Check if product is already in cart so udpdate quantity not adding new one
        cart.map((ele,ind)=>{
          if (ele.sku === product.sku) {
            if (product.colors) {
              if (ele.color === product.color) {
                is_found = true
                index = ind
              }
            }else {
              is_found = true
              index = ind
            }
          }
        })

        // product is already in the cart
        if (is_found) {
          let  newElement = cart[index]
          if ( newElement["qty"] + product.qty > +product.max_purchase_qty) {
            toast.error(product.title +": Quantity is out of acceptable range, max quantity is " + product.max_purchase_qty + " piece/pieces")
            setLoading(false);
            continue
          }
          newElement["qty"] =  newElement["qty"] + product.qty
          setLoading(false)
          localStorage.setItem("cart", JSON.stringify(cart));
          set_cart(dispatch(set_cart(cart)))
          toast.success(product.title  + ": Added.",{})
          continue
        }

        // if not in cart
        cart.push({
            ...product,
            color: product.color || "",
            qty: product.qty,
            img1: product.img1,
            slug: product.slug 
        });
  
        // Add new Data to localcart and update reducx
        localStorage.setItem("cart", JSON.stringify(cart));
        set_cart(dispatch(set_cart(cart)))
        toast.success(product.title + ": Added.")
        setLoading(false);
        continue
      }
      else {
        if ( product.qty > +product.max_purchase_qty) {
          toast.error(product.title +": Quantity is out of acceptable range, max quantity is " + product.max_purchase_qty + " piece/pieces")
          setLoading(false);
          continue
        }
        productsToUpload.push({product_sku: product.sku, product_color: product.color || "none", product_qty: product.qty})
      }

      // Upload Products 


      postData.append("session", session);
      postData.append("products", JSON.stringify(productsToUpload));

      for (let i = 0; i < 100; i++) {
        try {
          let response = await fetch(`${api}add_build_your_pc_products.php`, {
            method: "POST",
            body: postData,
          });
          let responseData = await response.text();
          responseData = JSON.parse(responseData);
          setLoading(false);
          if (responseData.state) {
            toast.success("Products have been successfully added to your cart.", {autoClose: 600})
            selectedProducts.map((ele,index)=>{
              // Update      else {
                add_to_cart(dispatch(add_to_cart({
                  ...ele,
                  color: ele.color || "",
                  qty: ele.qty,
                  img1: ele.img1,
                  slug: ele.slug 
              })))
           
            })
            if(response.res) {
              if (responseData.res.msg === "New session" ) {
                const expiryDate = new Date(responseData.exp);
                document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                localStorage.setItem("fullname",responseData.res.username);
                dispatch(set_username(localStorage.getItem("fullname")));
              }
            }
          

          }else {
            if (responseData.failed_products_skus) {
              const failedProducts = responseData.failed_products_skus

              selectedProducts.map((ele,index)=>{
                console.log(ele)
                let msg = failedProducts[ele.sku]; 
                if (msg) {
                  toast.error(`${ele.title}: ${msg}`)
                }
                // Update Local Cart
                else {
                  add_to_cart(dispatch(add_to_cart({
                    ...ele,
                    color: ele.color || "",
                    qty: ele.qty,
                    img1: ele.img1,
                    slug: ele.slug 
                })))
                }
              })

            }
          }

          return;
        } catch (error) {
          console.log(error)
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        return;
      }
    }

  };

  const handleAddToCart = async () => {
    if (!selectedProducts.length) {
      toast.error("Please select at least one product.")
      return
    }
    addToCart()
  };

  const calculateTotal = () => {
    let total = 0;
    selectedProducts.map((product) => {
      console.log(product)
      if (!product.has_nodiscount) {
        if (product.discount.type === "percent") {
          total += +(+product["price"] * (1 - product.discount.value)) * product.qty;
        }
      } else {
        total += +product["price"] * product.qty;
      }
    });
    return total
  }


  // Update Total when any update to products
  useEffect(() => {
    const total = calculateTotal()
    setTotal(total);
  }, [selectedProducts]);

  return (
    <div className="relative">
      <Nav />
      <Header onSearchClick={handleSearchClick} />
      <BreadCrump
        items={[
          { name: "HOME", to: "/" },
          { name: "BUILD YOUR PC", to: "/build-your-pc" },
        ]}
      />

  <Helmet>
    <title>PuZzLe Technology | Build Your PC</title>
    <meta name="description" content="Build Your PC with our UI friendly and easy tool"/>  

  </Helmet>

      <main className="flex flex-col relative gap-4  xl:flex-row-reverse mt-10  text-white  px-mobile md:px-tablet xl:px-pc py-6 md:py-10">
        {/* Total & Add to Cart & Remove All */}
        <div className="xl:sticky top-2 bg-bw-02  s md:w-full xl:w-96 sm:w-full rounded-2xl p-6 h-56 flex  flex-col justify-between">
          <span className="flex justify-between font-bold  2xl:text-xl">
            Total <span className="text-end font-medium">{total.toLocaleString()} LE</span>
          </span>

          <div className="flex flex-col gap-6">
            <div
              className="h-12 bg-blue font-bold flex justify-center items-center rounded-lg cursor-pointer select-none"
              onClick={handleAddToCart}
            >
              Add To Cart
            </div>

            <div
              className="h-12 bg-red font-bold flex justify-center items-center rounded-lg cursor-pointer select-none"
              onClick={() => dispatch(remove_all())}
            >
              Remove All
            </div>
          </div>
        </div>

        {/* Categories */}
        <div className="flex-1">
          <div className="flex flex-wrap gap-2 w-full">
            {Object.entries(products).map(([key, value]) => (
              <div key={value.id} className="w-full">
                <SelectProductComponent
                  name={key}
                  slug={value.slug}
                  categoryID={value.id}
                />
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Select Products Pop up */}
      {currentCategorySlug && (
        <>
          <div onClick={() => setCurrentCategorySlug(null)}>
            <Overlay></Overlay>
          </div>

          <div className="fixed z-50 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-h-[90%] w-[95%] mb-6 overflow-auto bg-white p-4 rounded-lg">
            <SelectProducsByCategory
              categorySlug={currentCategorySlug}
            ></SelectProducsByCategory>
          </div>
        </>
      )}

      {loading && <Loader />}
      {loading && <Overlay />}
      {isSearching && <SearchInput />}
      {isSearching && (
        <div onClick={() => setIsSearching(false)}>
          <Overlay />
        </div>
      )}
      <Footer />
      <Cart />
      <MobileMenu />
      <BottomNav currentPage="" />
      <ToastContainer />
    </div>
  );
}

export default BuildYourPCPage;
