import Nav from "../components/Nav";
import SearchInput from "../components/search-input.component";
import Overlay from "../components/Overlay";
import MobileMenu from "../components/menu.component";
import BottomNav from "../components/bottom-nav.component";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/footer.component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CartIcon from "../assets/icons/cart.svg";
import BreadCrump from "../components/BreadCrump.component";
import Profile from "../assets/icons/profile.svg";
import Logout from "../assets/icons/logout.svg";

import Wishlist from "../assets/icons/heart.svg";
import Cart from "../components/cart.compontent";

function MyAccount() {
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();

  const breadcrumbItems = [
    { name: "HOME", to: "/" },
    { name: "My Account", to: "/my-account" },
  ];

  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };

  const handleLogOut = () => {
    document.cookie =
      "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location = "/";
  };

  useEffect(() => {
    // const session = localStorage.getItem("session")
    // if (!session) {
    //   navigate("/login")
    // }
  }, []);

  return (
    <>
      <Helmet>
        <title>PuZzLe Technology - My Account</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Nav />
      <Header onSearchClick={handleSearchClick} />
      <BreadCrump items={breadcrumbItems} />

      <div className="flex flex-wrap gap-6 justify-center mt-11 min-h-96">
        <NavLink to={"/my-account/orders"}>
          <div className="p-6 flex flex-col items-center justify-center gap-2 w-80  md:w-60 md:h-40 bg-bw-03 border-2 border-bw-04 rounded-2xl">
            <img src={CartIcon} alt="icon" className="w-[72px]" />
            <span className="font-bold text-['cairo'] text-xl text-white select-none">
              Orders
            </span>
          </div>
        </NavLink>

        <div className="p-6 flex flex-col items-center justify-center gap-2 w-80  md:w-60 md:h-40 bg-bw-03 border-2 border-bw-04 rounded-2xl">
          <img src={Profile} alt="icon" className="w-[72px] " />
          <span className="font-bold text-['cairo'] text-xl text-white select-none">
            My Profile
          </span>
        </div>

        <NavLink to={"/wishlist"}>
          <div className="p-6 flex flex-col items-center justify-center gap-2 w-80  md:w-60 md:h-40 bg-bw-03 border-2 border-bw-04 rounded-2xl">
            <img src={Wishlist} alt="icon" className="w-[72px] " />
            <span className="font-bold text-['cairo'] text-xl text-white select-none">
              Wishlist
            </span>
          </div>
        </NavLink>

        <div
          className="p-6 cursor-pointer flex flex-col items-center justify-center gap-2 w-80  md:w-60 md:h-40 bg-bw-03 border-2 border-bw-04 rounded-2xl"
          onClick={handleLogOut}
        >
          <img src={Logout} alt="icon" className="w-[72px] " />
          <span className="font-bold text-['cairo'] text-xl text-white select-none">
            Log Out
          </span>
        </div>
      </div>
      {isSearching ? (
        <>
          <SearchInput />
          <div onClick={() => setIsSearching(false)}>
            <Overlay />
          </div>
        </>
      ) : null}
           <Cart></Cart>
      <Footer />
      <MobileMenu />
      <BottomNav currentPage="/my-account" />
    </>
  );
}

export default MyAccount;
